iframe {
    border: none;
    display: block;
}

.player {
    width: 100%;
    height: 700px;
    border: none;
    display: block;
    background-color: #283538;
}

.iframe_link {
    width: 100%;
    background-color: #EFEFF1;
    align-items: center;
    margin-bottom: 15px;
    display: flex;
    padding-left: 15px;
    line-clamp: 1;
}

.iframe_link span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.iframe_link button {
    display: block;
    padding: 15px;
    margin-left: auto;
    border: none;
    padding: 12px 20px;
    background-color: rgb(255, 125, 50);
    border: none;
    color: #FFFFFF;
    cursor: pointer;
}

.mantine-Badge-inner {
    text-transform: none;
}