.pagination {
    margin-top: 15px;
}

.pagination button {
    margin-top: 0;
    margin-right: 10px;
    background-color: transparent;
    color: #00000a;
}

.pagination button.active {
    background-color: #FF5E00;
    color: #FFFFFF;
    cursor: default;
}

.pagination button:hover {
    background-color: transparent;
    text-decoration: underline;
}

.pagination button.active:hover {
    background-color: #00000a;
    text-decoration: none;
    color: #FFFFFF;
    cursor: default;
}

