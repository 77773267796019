.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 15px;
}

.grid-entry {
    border: 1px solid #eee;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.grid-entry abbr {
    text-decoration: none;
}

.grid-entry-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 5px 15px;
    font-weight: bold;
}

.grid-entry-header .grid-entry-header-left {
    margin-right: auto;
}

.grid-entry-header .grid-entry-header-right {
    display: flex;
    align-items: center;
}

.grid-entry-header .grid-entry-header-right > * {
    margin-left: 10px;
}

.grid-entry-content {
    padding: 15px 15px;
    padding-bottom: 0;
}

.grid-entry-actions {
    margin-top: auto;
    padding: 0 15px 15px;
}