@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Inter', Helvetica, Arial, sans-serif !important;
}

a {
    color: #25283D;
    text-decoration: none;
}

html, body, #root {
    min-width: 100%;
    height: 100%;
    background-color: #f6f8fb;
}

abbr {
    text-decoration: none;
}