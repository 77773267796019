.message-wrapper {
    position: fixed;
    bottom: 50px;
    right: 50px;
    height: 60px;
    width: 350px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 20px;
    z-index: 100;
}

.error {
    background-color: #CC4B47;
    color: #FFFFFF;
}

.success {
    background-color: #56B363;
    color: #FFFFFF;
}

.loading {
    background: url('../../assets/loading_white.gif') no-repeat 15px 50% #56B363;
    background-size: 30px;
    color: #FFFFFF;
}

.loading .message {
    margin-left: 40px;
}

.message {
    font-weight: bold;
}

.close {
    margin-left: auto;
    color: #FFFFFF;
    cursor: pointer;
}