.sidebar .sidebar-content {
    width: inherit;
    max-width: inherit;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #eee;
    background-color: #FFF;
    position: sticky;
    top: 0;
    height: 100vh;
}

.sidebar .logo {
    padding: 20px;
    background-color: #00000a;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 135px;
}

.sidebar abbr {
    text-decoration: none;
}

.sidebar .logo a {
    width: 80%;
}

.sidebar ul > a {
    display: block;
    padding: 15px 25px;
    border-left: 5px solid transparent;
}

.sidebar ul > a:hover {
    box-sizing: border-box;
    border-left: 5px solid #FF5E00;
}

.sidebar ul li {
    list-style: none;
    display: flex;
    align-items: center;
}

.sidebar ul li svg {
    margin-right: 10px;
}

.sidebar ul > li.active {
    background-color: #FF5E00;
    border-radius: 5px;
}

.sidebar ul > li.active > a {
    color: #FFFFFF;
}

.sidebar .bottom-menu {
    margin-top: auto;
    display: flex;
    padding: 15px;
}

.bottom-menu .user-card {
    display: flex;
    align-items: center;
    margin-right: auto;
}

.bottom-menu .user-card img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
}

.bottom-menu .user-card div {
    display: flex;
    flex-direction: column;
}

.bottom-menu .user-name {
    font-weight: bold;
}

.bottom-menu .action {
    color: #FF5E00;
}

.bottom-menu .action:hover {
    text-decoration: underline;
    cursor: pointer;
}

.bottom-menu  button {
    border: none;
    background-color: transparent;
}

.notification-content {
    display: flex;
    flex-direction: column;
}

.notification-content .notification-date {
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #8d8d8d;
}