.fullpage-error {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fullpage-error div {
    text-align: center;
    font-size: 30px;
    font-weight: 900;
    color: #00000a;
}

.fullpage-error img {
    filter: grayscale(30%);
}
