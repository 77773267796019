.iteleport-section {
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    flex: auto;
}

.iteleport-section .left {
    margin-right: auto;
}

.iteleport-section .section-header {
    padding-bottom: 15px;
    display: flex;
    align-items: center;
}

.iteleport-section .section-header > svg {
    margin-right: 10px;
}

.iteleport-section .section-header .left-section {
    margin-right: auto;
    align-self: center;
}

.iteleport-section .section-header .right-section {
    display: flex;
}

.iteleport-section .section-header .right-section > * {
    margin-left: 15px;
}

.iteleport-section .section-header h1 {
    font-size: 18px;
}

.iteleport-section .section-content {
    border: 1px solid #eee;
    background-color: #FFFFFF;
    border-radius: 10px;
    position: relative;
}

.iteleport-section.without-border .section-content {
    border: none;
}

.iteleport-section .section-content > .mantine-Progress-root {
    border-radius: 0;
}

.iteleport-section .section-content > .mantine-Progress-root .mantine-Progress-bar {
    border-radius: 10px 10px 0 0;
}

.iteleport-section .section-content .section-content-body {
    padding: 20px;
}

.iteleport-section.without-border .section-content .section-content-body {
    padding: 0;
}

.iteleport-section .section-content.no-background {
    padding: 0;
    background-color: transparent;
    border: none;
}

.iteleport-section .section-content .search-box {
    width: 100%;
    border-radius: 5px 5px 0 0;
    border: 1px solid #eee;
    border-top: 5px solid #eee;
    padding: 16px;
    margin-bottom: 15px;
}

.iteleport-section .section-content .search-box:hover {
    border-top: 5px solid #FF5E00;
}