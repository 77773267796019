.dashboard {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 260px auto;
}

.dashboard .dashboard-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.dashboard .main-content {
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    gap: 30px;
    align-content: flex-start;
}

.dashboard .left-content {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    flex-direction: column;
}

.dashboard .left-content.row {
    flex-direction: row;
}

.dashboard .left-content.row .section-box {
    flex: auto;
}

.dashboard .right-content {
    width: 350px;
}

.dashboard .right-content > div {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px;
}

@media (max-width: 1000px) {
    .dashboard .main-content {
        flex-direction: column;
    }

    .dashboard .left-content {
        margin-right: 0;
    }

    .dashboard .right-content {
        margin-top: 20px;
        max-width: none;
    }
}

@media (max-width: 660px) {
    .dashboard {
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 0;
        padding-left: 30px;
        padding-right: 30px;
    }

    .dashboard .right-content {
        margin-top: 0;
        margin-right: 0;
        padding-top: 0;
    }

    .dashboard .page-top {
        padding-right: 0;
    }

    .dashboard .sidebar {
        max-width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
    }

    .dashboard .sidebar .sidebar-content {
        top: 0;
        bottom: 0;
        pointer-events: none;
        background-color: transparent;
    }

    .dashboard .sidebar .logo {
        /**height: inherit;**/
        padding: 10px;
        border-radius: 0;
        pointer-events: auto;
    }

    .dashboard .sidebar .menu {
        display: flex;
        overflow-x: scroll;
        background-color: #ff7d32;
        pointer-events: auto;
    }

    .dashboard .sidebar .menu a {
        color: #FFF;
    }

    .dashboard .sidebar .bottom-menu {
        width: 100%;
        background-color: #FFF;
        pointer-events: auto;
        position: fixed;
        bottom: 0;
        /**padding: 0 10px;**/
        border-top: 2px solid #ff7d32;
    }

    .dashboard .sidebar .bottom-menu .user-card {
        padding: 5px;
    }

    .dashboard .dashboard-wrapper {
        margin-top: 121px;
        padding-bottom: 45px;
    }

}
