.tooltip {
    width: 15px;
    height: 15px;
    background: url("../../assets/tooltip_icon.png");
    background-position: left;
    background-size: 15px;
    background-repeat: no-repeat;
    margin-left: 5px;
    position: relative;
}

.tooltip:hover {
    cursor: pointer;
}

.tooltip .tooltip-content {
    width: max-content;
    max-width: 300px;
    padding: 15px;
    background-color: #171D29;
    color: #FFFFFF;
    position: absolute;
    bottom: 0;
    left: 18px;
    border-radius: 5px 5px 5px 0;
    align-items: center;
    z-index: 11;
    display: none;
}

.tooltip:hover .tooltip-content {
    display: flex;
}

.tooltip-content:hover {
    cursor: initial;
}

.tooltip .tooltip-content img {
    height: 100%;
    margin-right: 15px;
}

@media (max-width: 660px) {
    .tooltip .tooltip-content {
        right: 18px;
        left: auto;
    }
}