.draggrable-card {
    overflow: visible;
}

.draggrable-card .card-title {
    cursor: pointer;
}

.draggrable-card .draggrable-icon {
    position: absolute;
    z-index: 1;
    margin: 5px;
    cursor: grab;
}

.draggrable-card .draggrable-icon.no-position {
    position: inherit;
}

.draggrable-card .draggrable-icon:active {
    cursor: grabbing;
}

.draggrable-card img {
    cursor: grab;
}

.draggrable-card img:active {
    cursor: grabbing;
}

.draggrable-title {
    position: absolute;
    bottom: 0;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
}