.custom-modal .mantine-ScrollArea-viewport {
    overflow: hidden !important;
}

.custom-modal .mantine-Modal-content > div, .custom-modal .mantine-ScrollArea-root {
    height: 100%;
    max-height: inherit;
}

.custom-modal .mantine-ScrollArea-viewport > div {
    display: flex !important;
    flex-direction: column !important;
    height: 100%;
}