.page-wrapper {
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    overflow-wrap: break-word;
}

.page {
    width: 100%;
    max-width: 500px;
    border-radius: 5px;
    border: 1px solid #B3ABAB;
    margin: 0 auto;
    padding: 20px;
}

.page > .logo-wrapper {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.page > .logo-wrapper img {
    width: 100%;
    height: auto;
    max-width: 277px;
}