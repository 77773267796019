.main-page {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    position: relative;
    flex-direction: column;
}

.main-page .page-header {
    width: 100%;
    display: flex;
    padding: 30px;
    background-color: #FFF;
    position: sticky;
    top: 0;
    z-index: 10;
}

.main-page .content-wrapper {
    width: 100%;
    height: 100%;
    padding: 60px 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 30px;
    flex: 1;
}

.main-page > .content-wrapper {
    width: 100%;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 30px;
    position: relative;
}

.main-page .page-left {
    flex: 1;
    min-width: 450px;
}

.main-page .page-right {
    min-width: 350px;
    max-width: 350px;
}

.main-page .page-left, .main-page .page-right {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

/* MANTINE */
.no-text .mantine-Button-icon {
    margin-right: 0;
}

.mantine-Pagination-item {
    border: none;
}

.mantine-Pagination-item:hover {
    text-decoration: underline;
}

.mantine-Tooltip-root {
    display: flex;
    margin-left: 10px;
}

.mantine-Tooltip-root svg {
    margin-right: 0 !important;
}

.mantine-Container-root {
    position: relative;
}

.mantine-Modal-body {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.mantine-Modal-body form {
    width: 100%;
}

.main-page > .mantine-Tabs-root {
    width: 100%;
}

.main-page > .mantine-Tabs-root > .mantine-Tabs-tabsList {
    position: fixed;
    width: 100%;
    padding: 0 30px;
    background-color: #FFF;
    z-index: 10;
}

.main-page > .mantine-Tabs-root > .mantine-Tabs-panel {
    padding: 0;
}

.mantine-Calendar-calendarBase .day-icon::after {
    content: '2 agendamentos';
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-size: 15px;
    font-weight: bold;
    display: inline;
}