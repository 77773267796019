.player-button {
    position: absolute;
    z-index: 1;
    bottom: 20px;
    right: 20px;
    background: none;
    border: 2px solid #FFFFFF;
    font-weight: bold;
    color: #FFFFFF;
    padding: 20px;
    cursor: pointer;
}