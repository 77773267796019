.totem-screen-wrapper {
    width: 100%;
}

.totem-screen-wrapper > div {
    aspect-ratio: 16/9;
    border-radius: 0;
    position: relative;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.totem-screen-wrapper > div:hover::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
}

.totem-screen-wrapper .mantine-Badge-root {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border-radius: 5px;
}

.totem-screen-wrapper:hover .mantine-Badge-root {
    background-color: 'white';
    color: white;
    border-radius: 5px;
}