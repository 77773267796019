.page-top {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 0;
}

.page-top .left {
    display: flex;
    align-items: center;
}

.page-top .left svg {
    margin-right: 10px;
}

.page-top .right {
    margin-left: auto;
}

.page-top h1 {
    font-size: 25px;
    font-weight: 900;
}

.page-top .secundary {
    padding: 0;
    background-color: transparent;
    border: none;
    color: #00000a;
    margin-left: 15px;
}

.page-top button {
    margin-left: 15px;
}

.page-top .secundary:hover {
    text-decoration: underline;
    background-color: transparent;
    border: none;
    cursor: pointer;
}