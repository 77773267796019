.section-box {
    border-radius: 3px;
    display: flex;
    flex-direction: column;
}

.section-box:last-of-type {
    margin-bottom: 0;
}

.section-box .margin-top {
    margin-top: 15px;
}

.section-box a {
    color: #FF985D;
}

.section-box a:hover {
    text-decoration: underline;
    cursor: pointer;
}

.section-box .section-box, .modal .section-box {
    border: none;
    margin-bottom: 0px;
}

.section-box .left {
    margin-right: auto;
}

.section-box .section-header {
    padding-bottom: 15px;
    display: flex;
    align-items: center;
}

.section-box .section-box .section-header, .modal .section-box .section-header {
    background: none;
    padding: 0px;
    display: flex;
    color: #00000a;
    align-items: center;
}

.section-box .section-header img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 15px;
}

.section-box .section-header img.small {
    width: 15px;
    height: 15px;
}

.section-box .section-header .left-section {
    margin-right: auto;
    align-self: center;
}

.section-box .section-header .right-section {
    display: flex;
}

.section-box .section-header .right-section .secundary {
    color: #00000a;
}

.section-box .section-header .right-section button {
    margin-top: 0;
    margin-left: 15px;
}

.section-box .section-header h1 {
    font-size: 20px;
    text-transform: capitalize;
}

.section-box .section-content {
    padding: 15px;
    border: 1px solid #eee;
    background-color: #FFFFFF;
    border-radius: 10px;
}

.section-box .section-box .section-content, .modal .section-box .section-content {
    padding: 15px 0;
    border: none;
}

.modal .section-box .section-content:last-of-type {
    padding-bottom: 0px;
}

.section-box form img {
    max-width: 100%;
}

.section-box span.form-input {
    display: block;
    margin-bottom: 10px;
}

.section-box span.form-input > span {
    display: flex;
    font-weight: bold;
    margin-bottom: 5px;
}

.section-box span.form-input .info {
    font-weight: normal;
}

.section-box span.form-input .suffix {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #d4d4d4;
    font-weight: bold;
    color: #5e5e5e;
}

.section-box span.form-input .circle-center {
    align-self: center;
    margin-top: 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
}

.section-box .group-inline {
    display: flex;
}

.section-box .group-inline span.form-input {
    flex: 1;
    margin-right: 10px;
    margin-left: 10px;
}

.section-box .group-inline span.form-input:first-of-type {
    margin-left: 0;
}

.section-box .group-inline span.form-input:last-of-type {
    margin-right: 0;
}

.section-box input, .section-box select, .section-box textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #D5D1D1;
    resize: none;
}

.section-box label.radio-label {
    display: flex;
    padding: 2px 0;
}

.section-box label.radio-label > span {
    margin-left: 5px;
}

.section-box input[type=radio] {
    width: inherit;
    padding: 10px;
}

.section-box input[type=checkbox] {
    width: inherit;
    margin-right: 10px;
    transform: scale(1.5);
    margin-left: 3px;
    margin-top: 5px;
}

.section-box .checkbox label {
    margin-right: 12px;
}

.section-box button, .btn, a.btn {
    padding: 12px 20px;
    background-color: rgb(255, 125, 50);
    border-radius: 5px;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
}

.block {
    display: block;
}

.section-box button:disabled, .btn:disabled,
.section-box button:hover:disabled, .btn:hover:disabled {
    padding: 12px 20px;
    border: none;
    border-radius: 3px;
    color: transparent;
    cursor: inherit;
    background: url('../../assets/loading_white.gif') rgb(204, 204, 204);
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}

.section-box button:hover, .btn:hover {
    background-color: #FF985D;
}

.section-box .text-editor {
    height: 530px;
}

.section-box .secundary {
    padding: 0;
    background-color: transparent;
    border: none;
    color: #00000a;
}

.section-box .secundary:hover {
    text-decoration: underline;
    background-color: transparent;
    border: none;
}

.section-box .section-header .secundary-light {
    color: #00000a;
}

.list-entry {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 15px;
    color: #00000a !important;
}

.list-entry:hover {
    text-decoration: none !important;
}

.list-entry:nth-child(odd) {
    background-color: #F8F8FA;
}

.list-entry:hover {
    background-color: #00000a;
    color: #FFFFFF !important;
}

.list-entry .list-icon {
    width: 50px;
    margin-right: 15px;
    border-radius: 50%;
}

.list-entry .list-content {
    flex: 1;
}

.list-entry .with-actions {
    display: flex;
}

.list-entry .list-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.list-entry .list-name {
    font-size: 22px;
    margin-right: 5px;
}

.list-entry .list-tag {
    background-color: #00000a;
    color: #FFFFFF;
    padding: 4px 10px;
    border-radius: 5px;
    font-size: 10px;
}

.list-entry:hover .list-tag {
    background-color: #FFFFFF;
    color: #00000a;
}

.list-entry .list-action {
    display: flex;
    flex-direction: row;
}

.list-entry .list-action * {
    margin-left: 10px;
}

.list-entry .list-details b {
    margin-left: 15px;
}

.list-entry .list-details b:first-of-type {
    margin-left: 0;
}

.list-entry .list-actions {
    margin-left: auto;
}

.list-section {
    display: flex;
    margin-bottom: 20px;
}

.list-section .list-info {
    display: flex;
    flex-direction: column;
    min-width: 95px;
}

.list-section .list-info .list-id {
    padding: 15px;
    text-align: center;
    background-color: #00000a;
    color: #FFFFFF;
}


.list-section .list-info .list-phase {
    background-color: #00000a;
    color: #FFFFFF;
    text-transform: uppercase;
    font-size: 10px;
    padding: 5px;
    text-align: center;
}

.list-section .list-content-wrapper {
    width: 100%;
}

.list-section .list-title-card {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #00000a;
    color: #FFFFFF;
    cursor: pointer;
}

.list-section .list-icon {
    font-weight: bold;
    margin-right: 10px;
}

.list-section .list-title {
    margin-right: auto;
    font-weight: bold;
    cursor: text;
}

.list-section .list-tag {
    padding: 0px 5px;
    border: 1px solid #d4d4d4;
    margin-left: 5px;
    cursor: text;
}

.list-section .list-content {
    padding: 15px;
    border: 1px solid #EFEFF1;
    flex: 1;
    display: none;
}

.list-section .list-content .download-link {
    display: block;
    width: 100%;
    padding: 5px 0;
}

.section-box .file-upload-input {
    display: none;
}

.section-box .file-upload {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: transparent;
    border: 3px dashed #EFEFF1;
    color: #00000a;
    transition: all ease-in 200ms;
    text-align: center;
}

.section-box .file-upload:hover {
    background-color: #FF985D;
    border-color: #FFFFFF;
    cursor: pointer;
}

.section-box .file-upload .loading {
    max-width: 100%;
}

.section-box .file-upload .choose-btn {
    font-size: 18px;
    font-weight: bold;
    transition: all ease-in 200ms;
}

.section-box .file-upload .file-list {
    font-size: 12px;
    transition: all ease-in 200ms;
}

.section-box .file-upload:hover .choose-btn, 
.section-box .file-upload:hover .file-list {
    color: #FFFFFF;
}

.progress-bar {
    width: 100%;
    height: 30px;
    background-color: #EFEFF1;
    display: block;
    overflow: hidden;
    margin-bottom: 20px;
}

.progress-bar .progress {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 0%;
    height: 100%;
    background: #00000a;
}

.progress-bar .progress .percent {
    color: #FFFFFF;
    font-weight: bold;
    margin-right: 15px;
}

.section-box .no-margin {
    margin: 0;
}

.section-box .loading {
    text-align: center;
}

.section-box .loading img {
    max-width: 50px;
}

.section-box .grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -10px 0 0 -10px;
}

.section-box .old-grid {
    border: none !important;
}

.section-box .grid::after {
    content: "";
    flex: auto;
}

.section-box .grid.single-column {
    flex-direction: column;
}

.section-box .grid .full-width {
    max-width: inherit;
}

.section-box .grid.hidden {
    max-height: 300px;
    overflow: hidden;
    position: relative;
}

.section-box .grid.hidden .grid-gradient {
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    left: 0;
    height: 50px;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 50%,rgb(255, 255, 255) 100%);
}

.section-box .display-all-button {
    width: 100%;
    text-align: center;
    padding: 15px;
    color: #FF985D;
}

.section-box .display-all-button:hover {
    cursor: pointer;
    text-decoration: underline;
}

.section-box .grid.horizontal {
    padding-bottom: 15px;
    flex-wrap: nowrap;
}

.section-box .grid.horizontal .grid-entry {
    min-width: 180px;
}

.section-box .grid-entry {
    display: flex;
    flex-direction: column;
    width: 20%;
    padding-left: 10px;
    padding-top: 10px;
}

.photos .grid-entry {
    overflow: hidden;
}

.photos .grid-entry .grid-image {
    width: 100%;
    max-height: 270px;
    overflow: hidden;
}

.section-box .grid-entry form {
    border: 1px solid #D5D1D1;
    padding: 10px;
}

.section-box .grid-entry .grid-header {
    display: flex;
    padding: 10px;
    background-color: #00000a;
    color: #FFFFFF;
}

.section-box .grid-entry .grid-header b {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.section-box .grid-entry .grid-header button {
    margin-top: 0;
}

.section-box .grid-entry .grid-header b {
    color: #FFFFFF;
}

.section-box .grid-entry .image-preview {
    height: 100px;
    background-size: cover;
    background-position: 50% 50%;
}

.section-box .grid-entry .grid-body {
    border: 1px solid #EFEFF1;
    padding: 10px;
}

.section-box .grid-entry .grid-body ul {
    list-style: none;
}

.section-box .grid-entry .grid-body ul li {
    margin-top: 5px;
    overflow-wrap: break-word; 
}

.section-box .grid-entry .grid-body ul li:first-of-type {
    margin-top: 0px;
}

.section-box .grid-entry .grid-body ul b {
    display: block;
    width: 100%;
}

.section-box .grid-entry .grid-button {
    width: 100%;
    margin-top: 5px;
}

.section-box .grid-entry .grid-button-full {
    width: 100%;
    border-radius: 0;
}

.section-box .grid-entry.same-width .grid-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.section-box .grid-entry.same-width .grid-body {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.section-box .grid-entry.same-width .grid-body ul {
    margin-bottom: 15px;
}

.section-box .grid-entry.same-width .grid-body a {
    margin-top: auto;
}

.section-box table {
    width: 100%;
    border-collapse: collapse;
}

.section-box th {
    background-color: #00000a;
    color: #FFFFFF;
    text-align: left;
    padding: 15px;
    border: 1px solid #2c2c30;
}

.section-box td {
    padding: 15px;
    border: 1px solid #D5D1D1;
}

.section-box audio {
    width: 100%;
}

.section-box .not-found-message {
    width: 100%;
    font-weight: bold;
    color: #D5D1D1;
}

.modal {
    position: fixed;
    background-color:rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.modal-content {
    background-color: #FFFFFF;
    padding: 15px;
    min-width: 50%;
    max-width: 80%;
    max-height: 90%;
    border-radius: 3px;
    overflow: auto;
}

.modal-content .section-box {
    height: 100%;
}

.modal-content .section-content {
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.modal-content .section-box .button-options {
    display: flex;
}

.modal-content .section-box .button-options button:first-child {
    margin-right: 15px;
}

.modal-content .section-box form {
    padding: 0;
    border: 0;
}

.modal-content img {
    max-width: 100%;
    max-height: 500px;
    align-self: center;
    border: 1px solid #EFEFF1;
    padding: 5px;
    margin-bottom: 15px;
}

.link-list {
    display: block;
    padding: 5px 0;
}

.full-width {
    width: 100% !important;
}

.no-border-radius {
    border-radius: 0 !important;
}

.center {
    text-align: center;
}

.loading-data {
    text-align: center;
}

.loading-data img {
    height: 40px;
    opacity: 0.1;
}

.section-box .flex {
    display: flex !important;
}

.section-box .column {
    flex-direction: column;
}

.section-box .extra-list {
    border: 1px solid #CCC;
    margin-top: 15px;
}

.section-box .extra-list > .extra-content {
    padding: 10px;
}

.section-box .extra-list .extra-title {
    padding: 10px;
    background-color: #00000a;
    display: flex;
}

.section-box .right {
    margin-left: auto;
    color: #FFFFFF;
}



/* NEW STYLING */
.section-box .section-content {
    position: relative;
}

.section-box .saving-overlay {
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 50;
    opacity: 0.8;
}

.section-box .light {
    color: #FFFFFF;
}

.section-box ul {
    list-style: none;
}

.section-box .card-wrapper {
    margin-bottom: 15px;
}

.section-box .card-wrapper .card-header {
    background-color: #00000a;
    color: #FFFFFF;
    padding: 15px;
    display: flex;
}

.section-box .card-wrapper .card-header .card-buttons {
    margin-left: auto;
}

.section-box .card-wrapper .card-header .card-title {
    font-weight: 600;
}

.section-box .card-wrapper .card-content {
    border: 1px solid #d4d4d4;
    padding: 15px;
}

.section-box .card-wrapper .card-content > *:last-child {
    margin-bottom: 0;
}

.section-box form > *:last-child {
    margin-bottom: 0;
}

.section-box form > *:last-child *:last-child {
    margin-bottom: 0;
}

.section-box .disabled {
    pointer-events: none;
}

.section-box .form-group {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    align-items: center;
}

.section-box .form-group.align-end {
    align-items: flex-end;
}

.section-box .form-group > .form-field, .section-box .form-group > .form-input, .section-box .form-group > .form-toggle {
    margin-bottom: 0;
}

.section-box .form-group > * {
    margin-right: 15px;
}

.section-box .form-group > *:last-child {
    margin-right: 0;
}

.section-box .form-field {
    display: flex;
    align-items: center;
    margin-bottom: 15px;   
    flex-wrap: wrap;
    flex: 1;
}

.section-box .form-field label {
    margin-bottom: 5px;
    font-weight: 300;
}

.section-box .form-field .pre-input {
    display: flex;
    width: 100%;
}

.section-box .form-field .pre-input div:first-child {
    background-color: #E3E4E5;
    padding: 15px;
    border-top: 5px solid #E3E4E5;
    display: flex;
    align-items: center;
    border-radius: 5px 0 0 0;
    font-size: 15px;
    font-weight: 300;
}

.section-box .form-field .pre-input input {
    border-radius: 0 5px 0 0;
}

.section-box .form-field input + label {
    margin-left: 15px;
    margin-bottom: 0;
    font-size: 16px;
}

.section-box .form-field > h2 {
    margin-bottom: 0;
}

.section-box .form-group.small-terms {
    margin-top: 15px;
}

.section-box .form-group.small-terms input {
    margin-left: 0;
}

.section-box form button {
    border-radius: 5px;
}

.section-box .form-group.small-terms button:disabled {
    background-image: none;
    color: #656568;
    border-radius: 5px;
}

.section-box .form-group.small-terms label {
    font-size: 12px;
    flex: 1;
    margin-left: 15px;
}

.section-box .sub-form {
    margin-left: 15px;
}

.section-box .sub-form > * {
    margin-left: 15px;
}

.section-box .sub-form > .form-field input[type=checkbox], .section-box .sub-form > .form-field input[type=radio] {
    margin-left: 0;
}

.section-box input, .section-box textarea {
    border-radius: 5px 5px 0 0;
    border: 1px solid #eee;
    border-top: 5px solid #eee;
    padding: 16px;
}

.ql-toolbar.ql-snow, .ql-container.ql-snow {
    border: 1px solid #eee;
    border-top: 5px solid #eee;
}

.section-box input:hover, .section-box textarea:hover {
    border-top: 5px solid #FF5E00;
}

.section-box input::placeholder, .section-box textarea::placeholder {
    color: #9B9EA3;
}

.section-box input[type=checkbox], .section-box input[type=radio] {
    all: unset;
    appearance: none;
    width: 21px;
    height: 21px;
    border: 1px solid #E3E4E5;
    display: inline-flex;
    background-color: #FFFFFF;
    background-image: none;
    border-radius: 5px;
    margin-left: 15px;
}

.section-box input[type=checkbox] ~ input, .section-box input[type=radio] ~ input {
    margin-left: 15px;
}

.section-box input[type=checkbox]:checked, .section-box input[type=checkbox]:hover,
.section-box input[type=radio]:checked, .section-box input[type=radio]:hover {
    border: 1px solid #FF5E00;
    background-position: center;
    background-repeat: no-repeat;
}

.section-box input[type=checkbox]:checked {
    background-image: url('../../assets/checkbox_checked_icon.png');
}

.section-box input[type=radio] {
    border-radius: 100%;
}

.section-box input[type=radio]:checked {
    background-image: url('../../assets/radio_check_icon.png');
}

.section-box .disabled input, .section-box .disabled input[type=checkbox], .section-box .disabled input[type=radio], .section-box .disabled textarea {
    background-color: #f1f1f1;
}

.section-box .disabled input::placeholder, .section-box .disabled textarea::placeholder {
    color: #f1f1f1;
}

.section-box form h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
}

.section-box form h2 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
}

.section-box form h3 {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 15px;
    margin-left: 15px;
}

.section-box form p {
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 300;
}

.section-box form p b {
    font-size: 16px;
    font-weight: 500;
}

.section-box form .select-button {
    height: 56px;
    width: 56px;
    border-radius: 5px;
    font-size: 24px;
}

.section-box .summary ul {
    list-style: none;
    
}

.section-box .summary ul li {
    display: flex;
    margin-bottom: 15px;
}

.section-box .summary span {
    font-size: 16px;
    font-weight: 300;
}

.section-box .summary .price {
    margin-left: auto;
}

.section-box .summary .total span {
    margin-left: auto;
    font-weight: 500;
}

.section-box .summary .total .price {
    margin-left: 15px;
}

.section-box .deliveryTime {
    padding: 15px;
    background-color: #EFEFF1;
    border-radius: 5px;
    margin-bottom: 15px;
    border: 1px solid #d4d4d4;
    font-weight: 300;
}

.section-box .deliveryTime b {
    font-weight: 600;
}

.section-box .timeline-entry {
    padding: 15px;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    margin-bottom: 15px;
}

.section-box .timeline-createdAt {
    font-size: 11px;
    font-weight: 600;
    color: #9B9EA3;
}

@media (max-width: 660px) {
    .section-box .form-group {
        flex-wrap: wrap;
        margin-bottom: 0;
    }

    .section-box .form-group > * {
        margin-right: 0;
        width: 100%;
    }

    .section-box .form-group > .form-field, .section-box .form-group > .form-input, .section-box .form-group > .form-toggle {
        margin-bottom: 15px;
    }

    .section-box .form-field input + label {
        flex: 1;
    }
}

.group-link > .mantine-Group-root {
    padding: 15px;
    text-decoration: none !important;
}

.group-link .mantine-Group-root a .mantine-Badge-root {
    text-decoration: none !important;
}

.group-link > .mantine-Group-root:nth-child(odd) {
    background-color: #f8f9fa;
}